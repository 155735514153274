// projectActions.js
export const handleAddProject = (state, action) => {
    console.log('action.payload: ',action.payload)
    const newProject = {
      id: (state.projects.length + 1).toString(),
      ...action.payload,
    };
    state.projects.push(newProject);
  };
  
  export const handleEditProject = (state, action) => {
    const { _id, ...updatedProject } = action.payload;
    const project = state.projects.find((project) => project._id === _id);
    if (project) {
      Object.assign(project, updatedProject);
    }
  };
  
  export const handleRemoveProject = (state, action) => {
    state.projects = state.projects.filter((project) => project._id !== action.payload);
  };
  