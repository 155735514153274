// SignIn.js
import React, { useState } from 'react';
import { useSigninMutation } from '../../redux/auth/authApi';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from '../../redux/auth/authSlice';
import { useNavigate, Link } from 'react-router-dom';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signin, { isLoading }] = useSigninMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize the navigate hook
  const [error, setError] = useState(null); // To handle login errors
  const { user } = useSelector((state) => state.auth); // Get user from Redux state

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await signin({ email, password }).unwrap();
      console.log('userData in sign in submit', userData);
      dispatch(loginSuccess(userData)); // Dispatch after login success
      if (userData) {
        navigate('/welcome', { replace: true }); // Redirect when user is logged in, with replace option
      }
    } catch (err) {
      console.error('Failed to login: ', err);
      setError('Invalid email or password. Please try again.'); // Display error
    }
  };

  return (
    <>
      <h2>Sign In</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Sign In'}
        </button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Show error message */}
      <Link to={'/signup'}>Sign Up</Link>
    </>
  );
};

export default SignIn;

