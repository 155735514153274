// taskApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://todo-api.darakbooks.com/api', // Replace with your API's base URL
  prepareHeaders: (headers) => {
    // Add any necessary headers here
    return headers;
  },
  responseHandler: async (response) => {
    const text = await response.text();
    // Handle empty responses
    if (!text) {
      return {};
    }
    try {
      return JSON.parse(text);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      throw new Error('Failed to parse server response');
    }
  },
});

export const taskApi = createApi({
  reducerPath: 'taskApi',
  baseQuery: baseQuery,
  tagTypes: ['Task'],
  endpoints: (builder) => ({
    fetchTasks: builder.query({
      query: () => '/tasks',
      providesTags: ['Task'],
    }),
    fetchTasksByProjectId: builder.query({
      query: (projectId) => `/projects/${projectId}/tasks`,
      providesTags: ['Task'],
    }),
    addTask: builder.mutation({
      query: (newTask) => ({
        url: '/tasks',
        method: 'POST',
        body: newTask,
      }),
      invalidatesTags: ['Task'],
    }),
    editTask: builder.mutation({
      query: ({ _id, ...updatedTask }) => ({
        url: `/tasks/${_id}`,
        method: 'PUT',
        body: updatedTask,
      }),
      invalidatesTags: ['Task'],
    }),
    archiveTask: builder.mutation({
      query: (_id) => ({
        url: `/tasks/${_id}/archive`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Task'],
    }),
    unarchiveTask: builder.mutation({
      query: (_id) => ({
        url: `/tasks/${_id}/unarchive`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Task'],
    }),
    pinTask: builder.mutation({
      query: (_id) => ({
        url: `/tasks/${_id}/pin`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Task'],
    }),
    unpinTask: builder.mutation({
      query: (_id) => ({
        url: `/tasks/${_id}/unpin`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Task'],
    }),
    reorderTaskUp: builder.mutation({
      query: ({ _id, parentId }) => ({
        url: `/tasks/${_id}/reorder-up`,
        method: 'PATCH',
        body: { _id, parentId },
      }),
      invalidatesTags: ['Task'],
    }),
    reorderTaskDown: builder.mutation({
      query: ({ _id, parentId }) => ({
        url: `/tasks/${_id}/reorder-down`,
        method: 'PATCH',
        body: { _id, parentId },
      }),
      invalidatesTags: ['Task'],
    }),
    removeTask: builder.mutation({
      query: (_id) => ({
        url: `/tasks/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Task'],
    }),
    removeTaskWithChildren: builder.mutation({
      query: (_id) => ({
        url: `/tasks/${_id}/remove-with-children`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Task'],
    }),
  }),
});

export const {
  useFetchTasksQuery,
  useFetchTasksByProjectIdQuery,
  useAddTaskMutation,
  useEditTaskMutation,
  useArchiveTaskMutation,
  useUnarchiveTaskMutation,
  usePinTaskMutation,
  useUnpinTaskMutation,
  useReorderTaskUpMutation,
  useReorderTaskDownMutation,
  useRemoveTaskMutation,
  useRemoveTaskWithChildrenMutation,
} = taskApi;
