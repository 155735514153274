// Logout.js
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/auth/authSlice';
import { useNavigate } from 'react-router-dom';
const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize the navigate hook
  
  const handleLogout = () => {
    dispatch(logout());
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('accessToken');
    navigate('/signin');
  };

  return <button onClick={handleLogout}>Logout</button>;
};

export default Logout;
