// store.js
import { configureStore } from '@reduxjs/toolkit';
import taskReducer from './task/taskSlice';
import projectReducer from './project/projectSlice';
import { taskApi } from './task/taskApi';
import { projectApi } from './project/projectApi';
import { authApi } from './auth/authApi';
import authReducer from './auth/authSlice';

export const store = configureStore({
  reducer: {
    tasks: taskReducer,
    projects: projectReducer,
    auth: authReducer, // Add auth slice
    [taskApi.reducerPath]: taskApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(taskApi.middleware, projectApi.middleware, authApi.middleware),
});



