// taskHelpers.js
export const findTaskById = (tasks, _id) => {
    return tasks.find(task => task._id === _id);
  };
  
  export const swapTaskOrder = (task1, task2) => {
    const temp = task1.order;
    task1.order = task2.order;
    task2.order = temp;
  };
  
  export const getMaxOrderForParentId = (tasks, parentId) => {
    const siblingTasks = tasks.filter(task => task.parentId === parentId);
    return siblingTasks.length > 0
      ? Math.max(...siblingTasks.map(task => parseInt(task.order, 10)))
      : 0;
  };
  