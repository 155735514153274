// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}
.ml20 {
  position: relative; /* Allows absolute positioning of ::before */
  margin-left: 20px; /* Indentation for child tasks */
  padding-left: 14px; /* Creates space for the arrow */
}
.ml20::before {
  content: '⤷';
  font-size: 20px;
  vertical-align: middle;
  color: #00838f;
  position: absolute; /* Position it relative to .ml20 */
  left: 0; /* Align it to the left of .ml20 */
  top: 20px; /* Center it vertically */
  transform: translateY(-50%); /* Adjusts for perfect vertical centering */
}
.ml0 {
  margin-left: 0;
  padding-left: 0;
}
/* Project list */
.projects-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.project-title {
  flex: 1 1; /* This will make the title take up the remaining space */
  font-size: 16px;
  text-align: left;
}

.project-operations {
  display: flex;
  gap: 10px; /* Add some space between the button and the link */
}

.project-operations-edit,
.project-operations-tasks {
  padding: 5px 10px;
  text-decoration: none;
}

.mr20px {
  margin-right: 20px;
}
.ml10px{margin-left: 10px;}

.card {
  padding: 2em;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,kBAAkB,EAAE,4CAA4C;EAChE,iBAAiB,EAAE,gCAAgC;EACnD,kBAAkB,EAAE,gCAAgC;AACtD;AACA;EACE,YAAY;EACZ,eAAe;EACf,sBAAsB;EACtB,cAAc;EACd,kBAAkB,EAAE,kCAAkC;EACtD,OAAO,EAAE,kCAAkC;EAC3C,SAAS,EAAE,yBAAyB;EACpC,2BAA2B,EAAE,2CAA2C;AAC1E;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA,iBAAiB;AACjB;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,SAAO,EAAE,yDAAyD;EAClE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS,EAAE,mDAAmD;AAChE;;AAEA;;EAEE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;AACA,QAAQ,iBAAiB,CAAC;;AAE1B;EACE,YAAY;AACd","sourcesContent":["#root {\n  max-width: 1280px;\n  margin: 0 auto;\n  padding: 2rem;\n  text-align: center;\n}\n.ml20 {\n  position: relative; /* Allows absolute positioning of ::before */\n  margin-left: 20px; /* Indentation for child tasks */\n  padding-left: 14px; /* Creates space for the arrow */\n}\n.ml20::before {\n  content: '⤷';\n  font-size: 20px;\n  vertical-align: middle;\n  color: #00838f;\n  position: absolute; /* Position it relative to .ml20 */\n  left: 0; /* Align it to the left of .ml20 */\n  top: 20px; /* Center it vertically */\n  transform: translateY(-50%); /* Adjusts for perfect vertical centering */\n}\n.ml0 {\n  margin-left: 0;\n  padding-left: 0;\n}\n/* Project list */\n.projects-list {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px;\n}\n\n.project-title {\n  flex: 1; /* This will make the title take up the remaining space */\n  font-size: 16px;\n  text-align: left;\n}\n\n.project-operations {\n  display: flex;\n  gap: 10px; /* Add some space between the button and the link */\n}\n\n.project-operations-edit,\n.project-operations-tasks {\n  padding: 5px 10px;\n  text-decoration: none;\n}\n\n.mr20px {\n  margin-right: 20px;\n}\n.ml10px{margin-left: 10px;}\n\n.card {\n  padding: 2em;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
