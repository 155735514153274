// Welcome.jsx
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Welcome = () => {
    const { user } = useSelector((state) => state.auth); // Get user from Redux state
    console.log('User in Welcome:', user);

    return (
      <div>
        {user ? (
            <p>Welcome, {user.username}</p>
        ) : (
            <p>User is not logged in</p>
        )}
      </div>
    );
}

export default Welcome;