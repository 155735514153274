// projectSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { handleAddProject, handleEditProject, handleRemoveProject } from './projectActions';

const initialState = {
  projects: [],
  selectedProjectId: null,
};

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    addProject: handleAddProject,
    editProject: handleEditProject,
    removeProject: handleRemoveProject,
    selectProject: (state, action) => {
      state.selectedProjectId = action.payload;
    },
  },
});

export const {
  addProject,
  editProject,
  removeProject,
  selectProject,
} = projectSlice.actions;

export default projectSlice.reducer;
