// taskActions.js
import { findTaskById, swapTaskOrder, getMaxOrderForParentId } from './taskHelpers';

export const handleArchiveTask = (state, action) => {
  const task = findTaskById(state.tasks, action.payload);
  if (task) {
    task.state = 'TASK_ARCHIVED';
    // Trigger the archive mutation to save to the database
    useArchiveTaskMutation()(task._id);
  }
};

export const handleUnarchiveTask = (state, action) => {
  const task = findTaskById(state.tasks, action.payload);
  if (task && task.state === 'TASK_ARCHIVED') {
    task.state = 'TASK_INBOX';
    // Trigger the unarchive mutation to save to the database
    useUnarchiveTaskMutation()(task._id);
  }
};

export const handlePinTask = (state, action) => {
  const task = findTaskById(state.tasks, action.payload);
  if (task) {
    task.state = 'TASK_PINNED';
  }
};

export const handleUnpinTask = (state, action) => {
  const task = findTaskById(state.tasks, action.payload);
  if (task && task.state === 'TASK_PINNED') {
    task.state = 'TASK_INBOX';
  }
};

export const handleAddTask = (state, action) => {
  const { title, parentId, projectId  } = action.payload;
  console.log('title: ',title);
  console.log('parentId: ',parentId);
  
  const maxOrder = getMaxOrderForParentId(state.tasks, parentId);

  const newTask = {
    id: (state.tasks.length + 1).toString(),
    title,
    parentId,
    order: (maxOrder + 1).toString(),
    state: 'TASK_INBOX',
    projectId, // Add projectId to the new task object
  };

  state.tasks.push(newTask);
};

export const handleEditTask = (state, action) => {
  const { _id, title } = action.payload;
  const task = findTaskById(state.tasks, _id);
  if (task) {
    task.title = title;
  }
};

export const handleReorderTaskUp = (state, action) => {
  const { _id, parentId } = action.payload;
  const siblings = state.tasks.filter(task => task.parentId === parentId).sort((a, b) => a.order - b.order);
  const index = siblings.findIndex(task => task._id === _id);

  if (index > 0) {
    const currentTask = siblings[index];
    const previousTask = siblings[index - 1];
    swapTaskOrder(currentTask, previousTask);
  }
};

export const handleReorderTaskDown = (state, action) => {
  const { _id, parentId } = action.payload;
  const siblings = state.tasks.filter(task => task.parentId === parentId).sort((a, b) => a.order - b.order);
  const index = siblings.findIndex(task => task._id === _id);

  if (index < siblings.length - 1) {
    const currentTask = siblings[index];
    const nextTask = siblings[index + 1];
    swapTaskOrder(currentTask, nextTask);
  }
};
