// App.jsx
import { Routes, Route } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Welcome from './Welcome';
import Logout from './LogOut';
import Dashboard from './Dashboard';
import ProtectedRoute from '../ProtectedRoute';
import { useSelector } from 'react-redux';

function App() {
  const { user } = useSelector((state) => state.auth); // Get user from Redux state
  return (
    <div>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/logout" element={<Logout />} />

        {/* Protected Routes */}
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/welcome" element={<ProtectedRoute element={<Welcome />} />} />
        
      </Routes>
        {user && (
          <>
            <h2>Welcome, {user.username}</h2>
            <Logout />
          </>
        )}
      </div>
  );
}

export default App;