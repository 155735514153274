// SignUp.js
import React, { useState } from 'react';
import { useSignupMutation } from '../../redux/auth/authApi';
import { useDispatch } from 'react-redux';
import { signupSuccess } from '../../redux/auth/authSlice';
// import { Link } from 'react-router-dom';
// import { withRouter } from '../../common/with-router';
import { useNavigate, Link } from 'react-router-dom';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [signup, { isLoading }] = useSignupMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize the navigate hook

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newUser = { email, username, password };
      const userData = await signup(newUser).unwrap();
      console.log('userData in signup', userData)
      dispatch(signupSuccess(userData)); // Dispatch the signupSuccess action after signup
      navigate('/signin');
    } catch (err) {
      console.error('Failed to signup: ', err);
    }
  };

  return (
    <>
    <h2>Sign Up</h2>
    <form onSubmit={handleSubmit}>
      <div>
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <label>Username</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="submit" disabled={isLoading}>
        {isLoading ? 'Signing up...' : 'Sign Up'}
      </button>
    </form>
    <Link to={'/signin'}>Sign In</Link>
    </>
  );
};

export default SignUp;
// export default withRouter(SignUp);