// projectApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://todo-api.darakbooks.com/api', // Replace with your API's base URL
  prepareHeaders: (headers) => {
    // Add any necessary headers here
    return headers;
  },
  responseHandler: async (response) => {
    const text = await response.text();
    if (!text) {
      return {};
    }
    try {
      return JSON.parse(text);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      throw new Error('Failed to parse server response');
    }
  },
});

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: baseQuery,
  tagTypes: ['Project'],
  endpoints: (builder) => ({
    fetchProjects: builder.query({
      query: () => '/projects',
      providesTags: ['Project'],
    }),
    fetchProjectsByOwnerId: builder.query({
        query: (ownerId) => `projects?ownerId=${ownerId}`,
        providesTags: ['Project'],
    }),
    addProject: builder.mutation({
      query: (newProject) => ({
        url: '/projects',
        method: 'POST',
        body: newProject,
      }),
      invalidatesTags: ['Project'],
    }),
    editProject: builder.mutation({
      query: ({ _id, ...updatedProject }) => ({
        url: `/projects/${_id}`,
        method: 'PUT',
        body: updatedProject,
      }),
      invalidatesTags: ['Project'],
    }),
    removeProject: builder.mutation({
      query: (_id) => ({
        url: `/projects/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Project'],
    }),
  }),
});

export const {
  useFetchProjectsQuery,
  useFetchProjectsByOwnerIdQuery,
  useAddProjectMutation,
  useEditProjectMutation,
  useRemoveProjectMutation,
} = projectApi;
