// taskSlice.js
import { createSlice } from '@reduxjs/toolkit';
import {
  handleArchiveTask,
  handleUnarchiveTask,
  handlePinTask,
  handleUnpinTask,
  handleAddTask,
  handleEditTask,
  handleReorderTaskUp,
  handleReorderTaskDown,
} from './taskActions';

const initialState = {
  tasks: [],
};

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    archiveTask: handleArchiveTask,
    unarchiveTask: handleUnarchiveTask,
    pinTask: handlePinTask,
    unpinTask: handleUnpinTask,
    addTask: handleAddTask,
    editTask: handleEditTask,
    reorderTaskUp: handleReorderTaskUp,
    reorderTaskDown: handleReorderTaskDown,
  },
});

export const {
  archiveTask,
  unarchiveTask,
  pinTask,
  unpinTask,
  addTask,
  editTask,
  reorderTaskUp,
  reorderTaskDown,
} = taskSlice.actions;

export default taskSlice.reducer;
