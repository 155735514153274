// App.jsx
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import Logout from './components/auth/LogOut';
import ProtectedRoute from './components/ProtectedRoute';
import ProjectList from './components/project/ProjectList';
import TaskList from './components/task/TaskList';
import './App.css';

function App() {
  const { user } = useSelector((state) => state.auth); // Get user from Redux state
  return (
    <div className="app-container">
      <Routes>
        {/* Protected Routes */}
        <Route path="/projects/:projectId/tasks" element={<ProtectedRoute element={<TaskList />} />} />
        <Route path="/projects" element={<ProtectedRoute element={<ProjectList />} />} />
        {/* Public Routes */}
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
        {user && (
          <>
            <h2>Welcome, {user.username}</h2>
            <Logout />
          </>
        )}
    </div>
  );
}

export default App;