// ProjectList.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchProjectsByOwnerIdQuery, useAddProjectMutation, useEditProjectMutation, useRemoveProjectMutation } from '../../redux/project/projectApi';
import ProjectModalBox from './ProjectModalBox';
import { selectProject } from '../../redux/project/projectSlice';
import { Link } from 'react-router-dom';

const ProjectList = () => {
  const { user } = useSelector((state) => state.auth); // Get user from Redux state
  const dispatch = useDispatch();
  const _ownerId = '66cda43587bec3ac4e3de664';
  const { data: projects = [], isLoading: loadingProjects, isError, error } = useFetchProjectsByOwnerIdQuery(_ownerId);
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);

  const [addProject] = useAddProjectMutation();
  const [editProject] = useEditProjectMutation();
  const [removeProject] = useRemoveProjectMutation();

  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [newProjectTitle, setNewProjectTitle] = useState('');
  const [editingProjectId, setEditingProjectId] = useState(null);

  useEffect(() => {
    if (projects.length > 0 && !selectedProjectId) {
      dispatch(selectProject(projects[0]._id));
    }
  }, [projects, selectedProjectId, dispatch]);

  const handleAddProject = () => {
    setNewProjectTitle('');
    setEditingProjectId(null);
    setIsProjectModalOpen(true);
  };

  const handleEditProject = (projectId, projectTitle) => {
    setNewProjectTitle(projectTitle || '');
    setEditingProjectId(projectId);
    setIsProjectModalOpen(true);
  };

  const handleModalClose = () => {
    setNewProjectTitle('');
    setIsProjectModalOpen(false);
    setEditingProjectId(null);
  };

  const handleSaveProject = async () => {
    if (newProjectTitle.trim()) {
      if (editingProjectId) {
        await editProject({ _id: editingProjectId, title: newProjectTitle });
      } else {
        await addProject({ title: newProjectTitle, ownerId: _ownerId });
      }
      handleModalClose();
    }
  };

  const handleRemoveProject = async () => {
    if (editingProjectId) {
      await removeProject(editingProjectId);
      handleModalClose();
    }
  };

  if (loadingProjects) return <div>Loading...</div>;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1>Project List</h1>
        <button onClick={handleAddProject}>
          <span className="icon-add"></span> Add Project
        </button>
      </div>
      <div className='list-container'>
        <ul className='ul-projects-list'>
          {projects.map(project => (
            <li key={project._id} className='projects-list list-item' >
              <div className='project-title'>{project.title}</div>
              <div className='project-operations'>
                  <button className='project-operations-edit edit-button' onClick={() => handleEditProject(project._id, project.title)}>
                    <span className={`icon-edit`} />
                  </button>
                  <Link to={'/projects/' + project._id + '/tasks'} className={'ml10px project-operations-tasks doc-list-button'}>
                    <span className={`icon-doc-list`} />
                  </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <ProjectModalBox
        isOpen={isProjectModalOpen}
        onClose={handleModalClose}
        onSave={handleSaveProject}
        projectTitle={newProjectTitle}
        onTitleChange={(e) => setNewProjectTitle(e.target.value)}
        isEditing={!!editingProjectId}
        onRemoveProject={handleRemoveProject}
      />
    </>
  );
};

export default ProjectList;


