// Dashboard.jsx
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div>
      <h2>Dashboard</h2>
      <p>Welcome, {user ? user.username : 'Guest'}!</p>
    </div>
  );
};

export default Dashboard;
