// authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { authApi } from './authApi'; // Import the authApi to use for handling async actions

const getUserFromSessionStorage = () => {
  try {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser && storedUser !== 'undefined') {  // Check if it's not undefined
      return JSON.parse(storedUser);
    }
    return null; // Return null if no valid user found
  } catch (error) {
    console.error('Error parsing user from sessionStorage:', error);
    return null;
  }
};

// Initial state, loading user and token from sessionStorage if they exist
const initialState = {
  user: getUserFromSessionStorage(),
  accessToken: sessionStorage.getItem('accessToken') || null,
  isAuthenticated: !!sessionStorage.getItem('accessToken'),
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Reducer to handle signup success
    signupSuccess: (state, action) => {
      console.log('loginSuccess: ', action.payload);
      state.message = action.payload.message;
    },
    // Reducer to handle login success
    loginSuccess: (state, action) => {
      if (action.payload.accessToken) {
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
        state.isAuthenticated = true;
        sessionStorage.setItem('user', JSON.stringify(action.payload.user)); // Save user to sessionStorage
        sessionStorage.setItem('accessToken', action.payload.accessToken);
      } else {
        console.error('No user object in login payload:', action.payload);
      }
    },
    
    // Reducer to handle logout
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.isAuthenticated = false;
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('accessToken');
      sessionStorage.clear();
    },    
  },
  extraReducers: (builder) => {
    // Signin async actions
    builder.addMatcher(authApi.endpoints.signin.matchPending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(authApi.endpoints.signin.matchFulfilled, (state, { payload }) => {
      state.user = payload.user;
      state.accessToken = payload.accessToken;
      state.isAuthenticated = true;
      state.loading = false;
      // Store user and accessToken in session storage
      sessionStorage.setItem('user', JSON.stringify(payload.user));
      sessionStorage.setItem('accessToken', payload.accessToken);
    });
    builder.addMatcher(authApi.endpoints.signin.matchRejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message || 'Signin failed';
    });

    // Signup async actions
    builder.addMatcher(authApi.endpoints.signup.matchPending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(authApi.endpoints.signup.matchFulfilled, (state, { payload }) => {
      state.message = payload.message;
      state.loading = false;
      // Store user and accessToken in session storage
      sessionStorage.setItem('message', JSON.stringify(payload.message));
    });
    builder.addMatcher(authApi.endpoints.signup.matchRejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message || 'Signup failed';
    });
  },
});

// Export logout action
export const { signupSuccess, loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;

